const CMS_TEXT =
{
    "1": "contact",
    "2": "event",
    "3": "bookmark",
    "4": "doc",
    "5": "upload",
    "6": "photo",
    "modules": "modules",
    "m1": "contact",
    "m2": "calendar",
    "m3": "bookmark",
    "m4": "doc",
    "m6": "photo",
    "m1s": "contacts",
    "m2s": "events",
    "m3s": "bookmarks",
    "m4s": "docs",
    "m6s": "photos",
    "initializing": "initializing",
    "yes": "yes",
    "no": "no",
    "create_a_free_account": "create a free account",
    "create_account": "create account",
    "creating_account": "creating account...",
    "logging_in": "logging in...",
    "logged_in": "you are logged in",
    "login": "login",
    "log_in": "log in",
    "log_out": "log out",
    "email_or_username": "email or username",
    "settings": "settings",
    "display_name": "display name",
    "dropbox": "dropbox",
    "account_timezone_setting": "account setting",
    "account": "account",
    "my_pad": "my pad",
    "display_name_optional": "display name (optional)",
    "timezone": "timezone",
    "email": "email",
    "password": "password",
    "reset password": "reset password",
    "confirm_password": "confirm password",
    "invalid password": "invalid password",
    "contact_folders": "contact folders",
    "bookmark_folders": "bookmark folders",
    "photo_folders": "photo folders",
    "calendars": "calendars",
    "root folder": "root folder",
    "trash": "trash",
    "folder_name": "folder name",
    "new_folder": "new folder",
    "update_folder": "update folder",
    "folder": "folder",
    "no_subfolder": "no sub-folders,",
    "no_subfolders": "no child folders,",
    "sharing": "sharing",
    "confirm sharing change": "confirm sharing change",
    "not_shared_to_anyone": "not shared to anyone",
    "activate": "activate",
    "de-activate": "de-activate",
    "export": "export",
    "search": "search",
    "read": "read",
    "write": "write",
    "cancel": "cancel",
    "delete": "delete",
    "favorite": "favorite",
    "unfavorite": "unfavorite",
    "update": "update",
    "edit": "edit",
    "save": "save",
    "saving": "saving",
    "saved": "is saved",
    "move": "move",
    "confirm": "confirm",
    "confirm_action": "confirm action",
    "confirm_deletion": "The following item will be deleted",
    "download": "download",
    "select_file": "select file",
    "done": "done",
    "restore": "restore",
    "moving": "moving",
    "moved": "is moved",
    "deleting": "deleting",
    "deleted": "is deleted",
    "empty_all": "empty all",
    "select_all": "select all",
    "clear_all": "clear all",
    "stop_sharing": "stop sharing",
    "doc_folders": "doc folders",
    "title_optional": "title (optional)",
    "new_bookmark": "new bookmark",
    "edit_bookmark": "edit bookmark",
    "new_event": "new event",
    "update_event": "update event",
    "new_contact": "new contact",
    "update_contact": "update contact",
    "new_doc": "new doc",
    "edit_doc": "edit doc",
    "title": "title",
    "notes": "notes",
    "color_label": "color label",
    "web_address": "web address",
    "add tags": "add, tags",
    "no_selection": "nothing is selected",
    "no_content": "no content on this page,",
    "upload to": "upload to",
    "first_name": "first name",
    "mi": "mi",
    "last_name": "last name",
    "business_name": "business name",
    "address": "address",
    "city": "city",
    "state_province": "state/province",
    "postal_code": "postal code",
    "country": "country",
    "quick_todo": "add to my to-do list",
    "empty_list": "empty",
    "no_implementation": "no implementation",
    "no_html_editor": "currently cannot edit html formatted note",
    "from": "from",
    "to": "to",
    "no_event": "no event",
    "nothing_selected": "nothing selected",
    "today": "today",
    "minute": "minute",
    "hour": "hour",
    "day": "day",
    "week": "week",
    "month": "month",
    "year": "year",
    "daily": "daily",
    "weekly": "weekly",
    "monthly": "monthly",
    "yearly": "yearly",
    "times": "times",
    "or_end_by": "or end by",
    "reminder": "reminder",
    "deliver_to": "deliver to",
    "before_it_starts": "before it starts",
    "this is an recurring event": "this is an recurring event",
    "delete all recurring events": "delete all recurring events",
    "delete this occurrence only": "delete this occurrence only",
    "delete this and all future one(s)": "delete this and all future one(s)",
    "starting": "starting...",
    "repeat": "repeat",
    "norepeat": "no repeat,",
    "mine": "mine",
    "will not longer be shared to you": "will not longer be shared to you",
    "it may take a few seconds to delete all items": "it may take a few seconds to delete all items",
    "account_login_failure": "your credential does not match with what we have",
    "account_deleted": "your account is deleted",
    "in queue": "in queue",
    "running": "running",
    "canceled": "canceled",
    "successful": "successful",
    "failed": "failed",
    "waiting": "waiting",
    "uploading": "uploading",
    "completed": "completed",
    "account_timezone_update_success": "account timezone is updated",
    "account_timezone_update_failure": "failed to update timezone",
    "folder_update_success": "folder is updated",
    "folder_update_failure": "folder update failed",
    "bookmark_entry_update_success": "the bookmark is updated",
    "bookmark_entry_update_failure": "failed to update the bookmark",
    "bookmark_entry_delete_success": "the bookmark is deleted",
    "bookmark_entry_delete_failure": "failed to delete the bookmark",
    "bookmarks_entry_delete_success": "the bookmarks are deleted",
    "bookmarks_entry_delete_failure": "failed to delete the bookmarks",
    "bookmark_entry_restore_success": "the bookmark is restored",
    "bookmark_entry_restore_failure": "failed to restore the bookmark",
    "bookmark_entry_move_success": "the bookmark is moved to the new folder",
    "bookmark_entry_move_failure": "failed to move the bookmark",
    "bookmarks_entry_move_success": "the bookmarks are moved to the new folder",
    "bookmarks_entry_move_failure": "failed to move the bookmarks",
    "contact_entry_update_success": "the contact is updated",
    "contact_entry_update_failure": "failed to update the contact",
    "contact_entry_delete_success": "the contact is deleted",
    "contact_entry_delete_failure": "failed to delete the contact",
    "contacts_entry_delete_success": "the contacts are deleted",
    "contacts_entry_delete_failure": "failed to delete the contacts",
    "contact_entry_restore_success": "the contact is restored",
    "contact_entry_restore_failure": "failed to restore the contact",
    "contact_entry_move_success": "the contact is moved",
    "contact_entry_move_failure": "failed to move the contact",
    "contacts_entry_move_success": "the contacts are moved to the new folder",
    "contacts_entry_move_failure": "failed to move the contacts",
    "event_entry_update_success": "the event is updated",
    "event_entry_update_failure": "failed to update the event",
    "event_entry_delete_success": "the event is deleted",
    "event_entry_delete_failure": "failed to delete the event",
    "event_entry_restore_success": "the event is restored",
    "event_entry_restore_failure": "failed to restore the event",
    "event_entry_move_success": "the event is moved",
    "event_entry_move_failure": "failed to move the event",
    "doc_entry_update_success": "the doc is updated",
    "doc_entry_update_failure": "failed to update the doc",
    "doc_entry_delete_success": "the doc is deleted",
    "doc_entry_delete_failure": "failed to delete the doc",
    "docs_entry_delete_success": "the docs are deleted",
    "docs_entry_delete_failure": "failed to delete the docs",
    "doc_entry_restore_success": "the doc is restored",
    "doc_entry_restore_failure": "failed to restore the doc",
    "doc_entry_move_success": "the doc is moved",
    "doc_entry_move_failure": "failed to move the doc",
    "docs_entry_move_success": "the docs are moved",
    "docs_entry_move_failure": "failed to move the docs",
    "photo_entry_delete_success": "the photo is deleted",
    "photo_entry_delete_failure": "failed to delete the photo",
    "photos_entry_delete_success": "the photos are deleted",
    "photos_entry_delete_failure": "failed to delete the photos",
    "photo_entry_restore_success": "the photo is restored",
    "photo_entry_restore_failure": "failed to restore the photo",
    "photo_entry_move_success": "the photo is moved",
    "photo_entry_move_failure": "failed to move the photo",
    "photos_entry_move_success": "the photos are moved to the new folder",
    "photos_entry_move_failure": "failed to move the photos",
    "moduleencryptorjob_bookmark": "encrypting bookmarks",
    "moduleexporterjob_contact": "exporting contacts",
    "moduleexporterjob_calendar": "exporting events",
    "moduleexporterjob_doc": "exporting docs",
    "moduleexporterjob_bookmark": "exporting bookmarks",
    "moduleexporterjob_photo": "exporting photos"
  }

export { CMS_TEXT }